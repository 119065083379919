/* eslint-disable no-console */
import { Container } from '@pwskills/rachnaui';
import React, { useContext, useEffect, useState } from 'react';
// import useScreenWidth from '@/hooks/useScreenWidth';
import { ICategory } from '@pwskills/rachnaui/components/Navbar/Navbar.types';
import {
  ICategoryDetails,
  ICompanyDetails,
  ICourses,
  IFaqsDetails,
  IExplore,
  IHomePageFilters,
  ISuccessStory,
} from '@/interfaces/homePage';
import dynamic from 'next/dynamic';
import HomePage from '@/api/homePage';
import { IError } from '@/interfaces/common';
import moEvent from '@/utils/moEngage';
import UserDetailsContext from '@/context/UserDetailsContext';
import { useRouter } from 'next/router';
import Hero from './Hero';
import BaseLayout from '../common/BaseLayout';
import OffersStrip from '../common/OffersStrip/OffersStrip';
// import MasterClass from './MasterClass';
// import OurProducts from './OurProducts';
// import OfferBanner from './OfferBanner';
// import OurAchievers from './OurAchievers';
// import SuccessStories from './SuccessStories';
// import Faqs from '../common/Faqs';
// import Banner from './Banner';
// import OurCategories from './OurCategories';
// import OurCoursesRevamp from './OurCoursesRevamp';
// import EssentialSkills from './EssentialSkills';
// import OnlineDegree from './OnlineDegree';
const MasterClass = dynamic(() => import('./MasterClass'));
const OnlineDegree = dynamic(() => import('./OnlineDegree'));
const OurCategories = dynamic(() => import('./OurCategories'));
const OurCoursesRevamp = dynamic(() => import('./OurCoursesRevamp'));
const Banner = dynamic(() => import('./Banner'));
// const OfferBanner = dynamic(() => import('./OfferBanner'));
const EssentialSkills = dynamic(() => import('./EssentialSkills'));
const OurProducts = dynamic(() => import('./OurProducts'));
const OurAchievers = dynamic(() => import('./OurAchievers'));
const SuccessStories = dynamic(() => import('./SuccessStories'));
const Faqs = dynamic(() => import('../common/Faqs'));

// import CampusEdge from './CampusEdge';

const Home = () => {
  const [companyLogos, setCompanyLogos] = useState<ICompanyDetails[]>([]);
  const [faqsList, setFaqsList] = useState<IFaqsDetails[]>([]);
  const [categoryData, setCategoryData] = useState<ICategory[]>([]);
  const [exploreCategoryData, setExploreCategoryData] = useState<IExplore[]>([]);
  const [freeCourses, setFreeCourses] = useState<ICourses[]>([]);
  const [freeCoursesFilters, setFreeCoursesFilters] = useState<IHomePageFilters[]>([]);
  const [successStories, setSuccessStories] = useState<ISuccessStory[]>([]);
  const [categoriesDetails, setCategoriesDetails] = useState<ICategoryDetails[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [, setShowMoreCategoryClicked] = useState(false);

  const { user } = useContext(UserDetailsContext);

  const router = useRouter();

  const fetchCompanyAndFaqsDetails = async () => {
    try {
      const response = await HomePage.getCompanyAndFaqs();

      if (response) {
        const CompanyDetails = response?.data?.main?.companies;
        setCompanyLogos(CompanyDetails);
        const faqsDetails = response?.data?.main?.faq;
        setFaqsList(faqsDetails);
        const categoryDetails = response?.data?.categories;
        setCategoryData(categoryDetails);
      }
    } catch (error: unknown) {
      // eslint-disable-next-line no-console
      console.error((error as IError)?.error);
    }
  };

  const fetchHomePageData = async () => {
    setIsLoading(true);
    try {
      const response = await HomePage.getHomePageData();
      if (response) {
        const freeCoursesData = (response as { data: { freeCourses: ICourses[] } }).data
          ?.freeCourses;
        const exploreCategory = (response as { data: { explore: IExplore[] } }).data?.explore;
        const freeCoursesFiltersData = (
          response as { data: { freeCoursesFilters: IHomePageFilters[] } }
        ).data?.freeCoursesFilters;
        const successStoriesData = (response as { data: { successStories: ISuccessStory[] } }).data
          ?.successStories;
        const categoriesData = (response as { data: { paidCourses: ICategoryDetails[] } }).data
          ?.paidCourses;

        setFreeCourses(freeCoursesData);
        setExploreCategoryData(exploreCategory);
        setFreeCoursesFilters(freeCoursesFiltersData);
        setSuccessStories(successStoriesData);
        setCategoriesDetails(categoriesData);
      }
    } catch (error: unknown) {
      // eslint-disable-next-line no-console
      console.error((error as IError)?.error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      fetchCompanyAndFaqsDetails();
      fetchHomePageData();
    }
  }, []);

  const handleShowMoreButtonClick = (value: boolean) => {
    setShowMoreCategoryClicked(value);
  };

  useEffect(() => {
    if (router?.isReady && user?._id) {
      const moEventData = {
        user_id: user?._id || '',
        phone_number: user?.phoneNumber || '',
        user_type: user?._id ? 'loggedin' : 'not_loggedin',
        portal_name: 'dashboard',
        Portal_source_URL: router.query.source || '',
        Portal_source_position: router.query.position || '',
        ...(user?.verified && user?.email && { email: user.email }),
      };
      moEvent('home_page_view', moEventData);
    }
  }, [user, router?.isReady]);

  return (
    <div className="relative">
      <div className="sticky top-0 z-[99999]">
        <OffersStrip />
      </div>
      <BaseLayout category={categoryData} pageName="home_page">
        <Hero />
        <OnlineDegree />
        <OurCategories categories={exploreCategoryData} isLoading={isLoading} />
        {/* <OurCourses /> */}
        <OurCoursesRevamp
          loading={isLoading}
          categoriesDetails={categoriesDetails}
          handleShowMoreButtonClick={handleShowMoreButtonClick}
        />
        <Banner />
        <MasterClass />
        {/* {!isLoading && <OfferBanner key={showMoreCategoryClicked.toString()} />} */}
        <EssentialSkills
          loading={isLoading}
          filterTiles={freeCoursesFilters}
          freeCoursesList={freeCourses}
        />
        <OurProducts />
        {/* <CampusEdge /> */}
        <div className="bg-gradient" id="achievers">
          {companyLogos && <OurAchievers companyLogos={companyLogos} />}
          <SuccessStories successStories={successStories} />
        </div>
        <Container className="py-20">
          {faqsList && <Faqs faqsList={faqsList} showViewAllButton />}
        </Container>
      </BaseLayout>
    </div>
  );
};

export default Home;
