import { Button, Typography } from '@pwskills/rachnaui';
import { useContext, useEffect, useState, useRef } from 'react';
import HomePage from '@/api/homePage';
import moEvent from '@/utils/moEngage';
import { Close } from '@pwskills/rachnaui/Icons';
import { IWebBanner } from '../../../interfaces/homePage';
import UserDetailsContext from '../../../context/UserDetailsContext';
import { domainUrl } from '../../../utils/common/env.constants';
import { dataLayer } from '../../../utils/common/dataLayer.utils';

const OffersStrip = ({ isProfessional = false }: { isProfessional?: boolean }) => {
  const [data, setData] = useState<IWebBanner[] | []>([]);
  const { user, isLoggedIn } = useContext(UserDetailsContext);
  const [isVisible, setIsVisible] = useState(true);
  const stripRef = useRef<HTMLDivElement | null>(null);

  const fetchWebBanner = async () => {
    try {
      const response = await HomePage.getWebBanner();
      if (response) {
        const webNotificationBanners = response?.data?.filter(
          (item) => item?.bannerType === 'WEB_NOTIFICATION'
        );
        setData(webNotificationBanners);
      }
    } catch (error: unknown) {
      // eslint-disable-next-line no-console
      // console.error((error as IError)?.error);
    }
  };

  const getURL = (link: string) => {
    if (link === `${domainUrl}`) {
      return `${link}/?position=top_banner`;
    }
    if (link === `${domainUrl}/courses`) {
      return `${link}/?source=top_banner`;
    }
    if (link.startsWith(`${domainUrl}/course/`)) {
      return `${link}/?source_position=top_banner`;
    }
    if (link === `${domainUrl}/learn`) {
      return `${link}/?source=top_banner`;
    }
    if (link.startsWith(`${domainUrl}/category/`)) {
      return `${link}/?position=top_banner&source=${domainUrl}`;
    }
    return link;
  };

  const handleBannerClick = (banner: IWebBanner) => {
    if (user?._id && isLoggedIn) {
      const moEventData: { [key: string]: any } = {
        user_id: user?._id || '',
        phone_number: user?.phoneNumber || '',
        user_type: isLoggedIn ? 'loggedin' : 'not_loggedin',
        banner_identifier: banner?._id,
        banner_message: banner.text,
        banner_link: banner?.link,
      };
      if (user?.verified && user?.email) moEventData.email = user.email;
      moEvent('banner_click', moEventData);
    }
  };

  const sendDataLayerEvent = (banner: IWebBanner) => {
    const dataLayerObj = {
      event: 'homepage_banner_click',
      banner_id: banner?._id,
      banner_url: banner?.link,
      banner_type: 'top',
    };
    dataLayer(dataLayerObj);
  };

  const handleIconClick = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      fetchWebBanner();
    }
  }, []);

  const throttle = <T extends (...args: any[]) => void>(
    func: T,
    limit: number
  ): ((...args: Parameters<T>) => void) => {
    let lastFunc: ReturnType<typeof setTimeout>;
    let lastRan: number;
    return (...args: any[]) => {
      if (!lastRan) {
        func(...args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(() => {
          if (Date.now() - lastRan >= limit) {
            func(...args);
            lastRan = Date.now();
          }
        }, limit - (Date.now() - lastRan));
      }
    };
  };

  useEffect(() => {
    const handleScroll = () => {
      const overview: HTMLDivElement | null = document?.querySelector('#overview');
      const strip = stripRef.current;
      const { scrollY } = window;

      if (!overview || !strip) return;

      const overviewBottom = overview.offsetTop + overview.offsetHeight * 0.5;

      if (scrollY >= overviewBottom) {
        if (strip.classList.contains('hidden')) return;
        strip.classList.add('hidden');
      } else {
        if (!strip.classList.contains('hidden')) return;
        strip.classList.remove('hidden');
      }
    };

    const throttledHandleScroll = throttle(handleScroll, 100);

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', throttledHandleScroll);
    }
    return () => window.removeEventListener('scroll', throttledHandleScroll);
  }, []);

  if (!isVisible || data.length === 0) return null;

  return (
    <div
      ref={stripRef}
      className={`relative gap-2 flex items-center top-25 w-full py-[6px] z-[10000] h-14 px-8 medium:!px-4 ${
        isProfessional ? 'bg-[#F8FAFB]' : 'bg-[#1B2124]'
      }`}
    >
      <div className="absolute right-4 hidden large:!flex cursor-pointer" onClick={handleIconClick}>
        <Close className={`cursor-pointer ${isProfessional ? 'text-black' : 'text-white'}`} />
      </div>

      <div className="flex justify-center items-center gap-3 medium:gap-4 w-full">
        {data.map((banner) => {
          const link = getURL(banner?.link);
          return (
            <div key={banner._id} className="flex gap-2 items-center">
              <Typography
                variant="semi-bold"
                component="body-regular"
                className={`text-[10px] medium:!text-[16px] line-clamp-3 ${
                  isProfessional ? 'text-gray-850' : 'text-white'
                }`}
              >
                {banner.text}
              </Typography>
              <Button
                size="small"
                className={`!text-[10px] !leading-[16px] medium:!text-[14px] medium:!leading-[22px] !px-2 medium:!px-4 !py-1 !font-bold large:!ml-4 whitespace-nowrap ${
                  isProfessional ? 'text-white' : 'text-gray-850 '
                }`}
                style={{ backgroundColor: isProfessional ? '#1B2124' : '#FFFFFF' }}
                onClick={() => {
                  handleBannerClick(banner);
                  sendDataLayerEvent(banner);

                  // Create a hidden anchor tag and click it
                  const anchor = document.createElement('a');
                  anchor.href = link;
                  anchor.rel = 'nofollow';
                  anchor.style.display = 'none';
                  document.body.appendChild(anchor);
                  anchor.click();
                  document.body?.removeChild(anchor);
                }}
              >
                {banner.urlText}
              </Button>
            </div>
          );
        })}
      </div>
      <div className="flex large:!hidden cursor-pointer" onClick={handleIconClick}>
        <Close className={`cursor-pointer ${isProfessional ? 'text-black' : 'text-white'}`} />
      </div>
    </div>
  );
};

export default OffersStrip;
